<template>
	<div class="partial partial--letters letters">
		<component :is="content" />
	</div>
</template>

<script>
const Robert = () => import(/* letters */ '@/views/partials/letters/Robert')
const Mike = () => import(/* letters */ '@/views/partials/letters/Mike')
const Susan = () => import(/* letters */ '@/views/partials/letters/Susan')

export default {
	name: 'PartialLetters',
	components: {
	},
	data: () => ({
		letter: 'robert',
	}),
	computed: {
		letters() {
			return {
				robert: Robert,
				mike: Mike,
				susan: Susan
			}
		},
		content() {
			return this.letters[this.letter]
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler: function(val) {
				const { letter } = val.params

				if (!letter) return
				this.letter = letter
				// this.content = this.letters[letter]
			},
		},
	}
}
</script>

<style scoped lang="scss">
.letters {
	background-color: white;

	::v-deep &__body {
		@include gutter('padding', 1);
		@include gutter('padding-top', 2);
		@include fontSize(18px);
		line-height: 1.5;
		margin-left: auto;
		margin-right: auto;
		width: 100%;

		@include media-query-min-width('tablet') {
			@include gutter('padding', 3);
		}

		@include media-query-min-width('desktop') {
			max-width: $content-container-lg;
			@include gutter('padding-top', 5);
		}

		p {
			@include gutter('margin-bottom', 1.5);
			margin-top: 0;
		}

		blockquote {
			@include gutter('margin-bottom', 2);
			@include fontSize(24px);
			color: color_('primary');
			font-style: italic;
			font-weight: bold;
			line-height: 1.4;
			margin: 0;
			padding: 0 0 0 24px;
			border-left: 8px solid color_('secondary');

			@include media-query-min-width('tablet') {
				@include gutter('margin-bottom', 4);
			}
		}

		h2 {
			@include fontSize(48px);
			@include gutter('margin-bottom', 1);
			color: color_('primary','dark');
			font-family: $font-family-secondary;
			font-weight: 900;
			line-height: 1;
			text-transform: uppercase;

			&.small {
				@include fontSize(32px);
				color: color_('primary','dark');
			}

			span {
				color: color_('primary');
			}

			@include media-query-max-width('phone') {
				br {
					display: none;
				}
			}
		}

		.body-columns {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			justify-content: space-between;

			@include media-query-min-width('tablet') {
				flex-direction: row;
			}

			&__content {
				// @include gutter('padding-right', 3);

				@include media-query-min-width('tablet') {
					@include gutter('padding-right', 3);
				}

				@include media-query-min-width('desktop') {
					@include gutter('padding-right', 6);
				}
			}

			&__images {
				display: flex;
				align-items: center;
				flex-direction: column;
				width: 100%;

				@include media-query-min-width('tablet') {
					display: block;
				}

				img {
					@include gutter('margin-bottom', 2);
					max-width: 100%;

					@include media-query-min-width('tablet') {
						@include gutter('margin-bottom', 4);
						max-width: 266px;
					}

					@include media-query-min-width('desktop') {
						max-width: initial;
					}
				}
			}

			&--small {
				@include media-query-min-width('desktop') {
					max-width: 70%;
					margin: 0 auto;
				}
			}

			&--reverse {
				flex-direction: column-reverse;

				@include media-query-min-width('tablet') {
					flex-direction: row-reverse;
				}

				.body-columns__content {
					@include media-query-min-width('tablet') {
						@include gutter('padding-left', 3);
						@include gutter('padding-right', 0);
					}

					@include media-query-min-width('desktop') {
						@include gutter('padding-left', 6);
						@include gutter('padding-right', 0);
					}
				}
			}
		}
	}
}
</style>
